var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('UserEditFields',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.makingApiRequest),expression:"makingApiRequest"}],key:_vm.customKey,ref:"formBuilderUserForm",attrs:{"addNewUserFields":_vm.addNewUserFields,"isAdmin":_vm.isCurrentUserAdmin ||
        (_vm.$getCurrentUser &&
          _vm.$getCurrentUser.role &&
          (_vm.$getCurrentUser.role === 'agencyAdmin' ||
            _vm.$getCurrentUser.role === 'subAdmin')),"isNew":false,"isSettings":false,"element-loading-text":_vm.loadingText,"isUserBanned":_vm.isUserBanned},on:{"validationChanged":_vm.validationChangeHandler}}),_c('ModalBase',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.makingApiRequest),expression:"makingApiRequest"}],attrs:{"name":"changePasswordModal","size":"medium","clickToClose":false,"showClose":true,"title":"Change Password","element-loading-text":_vm.loadingText,"position":"center","bottomButtonPrimary":{ key: 'save', disabled: _vm.disabled, label: 'Save' },"bottomButtonSecondary":{
      key: 'cancel',
      label: 'Cancel'
    }},on:{"primaryButtonClick":_vm.changePassword}},[_c('Password',{ref:"passwordComponent",attrs:{"isAdmin":_vm.isCurrentUserAdmin,"loading":_vm.makingApiRequest,"isNew":false},on:{"disable":function($event){return _vm.setDisabled($event)}}})],1),_c('saveChanges',{on:{"clickedSave":_vm.beforeRouteLeaveSave,"clickedDiscard":_vm.beforeRouteLeaveDiscardChanges}}),_c('ModalMessage',{attrs:{"name":"showModal","icon":['fal', 'exclamation-circle'],"iconClass":"text-red-600 text-6xl","title":_vm.modalTitle,"description":_vm.modalDescription,"showClose":true,"buttons":[
      {
        text: `Okay`,
        classList: [''],
        click: () => _vm.handleAction()
      },
      {
        text: `Cancel`,
        classList: ['bg-red-700'],
        click: () => _vm.$modal.hide('showModal')
      }
    ]}},[(_vm.actionType === 'restrict')?_c('form-builder',{ref:"formElement",staticClass:"mt-20",attrs:{"schemas":_vm.fields,"formValues":_vm.restrictedData || {}},on:{"formFieldChanged":_vm.formFieldChangeHandler}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }