import {
  useFormSection,
  useFormFieldGroup,
  useField
} from "@/components/FormBuilder/Helpers";

export const restrictOptions = [
  {
    label: "1",
    value: 1
  },
  {
    label: "2",
    value: 2
  },
  {
    label: "3",
    value: 3
  }
];
const fields = useFormSection(
  useFormFieldGroup(
    [
      // useField({
      //   key: "level",
      //   label: "Select Level",
      //   type: "select",
      //   options: restrictOptions,
      //   required: true
      // }),
      useField({
        key: "startDate",
        label: "Start Date",
        type: "date",
        required: true
      }),
      useField({
        key: "endDate",
        label: "End Date",
        type: "date",
        required: true
      })
    ],
    { layout: "2-col" }
  )
);

export const restrictedUser = [...fields];
