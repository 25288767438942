
import { userMapActions, userMapState } from "@/store/modules/user";
import { RestrictUserFields } from "@/store/modules/user/types";
import { IUserRestricted, IUserRoleStrings } from "@/types";
import Vue from "vue";

type IUserData = {
  restrictedData: {
    startDate: Date;
    endDate: Date;
  };
  loadingText: string;
  modalTitle: string;
  modalDescription: string;
  actionType: string;
  loading: boolean;
};

export default Vue.extend({
  data(): IUserData {
    return {
      restrictedData: { startDate: new Date(), endDate: new Date() },
      loadingText: "",
      modalTitle: "",
      modalDescription: "",
      actionType: "",
      loading: false
    };
  },
  methods: {
    ...userMapActions([
      "banUser",
      "unbanUser",
      "restrictUser",
      "restoreUser",
      "deRestrictUser",
      "deleteUser"
    ]),
    formFieldChangeHandler({
      key,
      value
    }: {
      key: RestrictUserFields;
      value: any;
    }): void {
      (this.restrictedData as IUserRestricted)[key] = value;
    },
    async restrictSingleUser(userId: string): Promise<void> {
      try {
        this.loadingText = "Restricting user. Please wait...";
        const userIds = [userId];
        const restrictData = this.restrictedData as IUserRestricted;
        if (restrictData?.startDate >= restrictData?.endDate) {
          return this.$appNotifyError(
            "End date should be greater than start date"
          );
        }
        await this.restrictUser({
          userIds,
          restrictData
        });
        this.$appNotifySuccess("Selected User is Restricted");
      } catch (error) {
        this.$appNotifyError(error.message);
        this.$bugSnagClient.notify(error);
      } finally {
        this.resetModalData();
      }
    },
    resetModalData(): void {
      this.$modal.hide("showModal");
      this.modalTitle = "";
      this.modalDescription = "";
      this.actionType = "";
      this.loading = false;
    },
    async deRestrictSingleUser(userId: string): Promise<void> {
      try {
        this.loadingText = "Derestricting user. Please wait...";
        await this.deRestrictUser([userId]);
        this.$appNotifySuccess("Selected User's Restriction is Reversed");
      } catch (error) {
        this.$appNotifyError(error.message);
        this.$bugSnagClient.notify(error);
      } finally {
        this.resetModalData();
      }
    },
    async restoreDeletedUser(userId: string): Promise<void> {
      try {
        this.loadingText = "Restoring deleted user. Please wait...";
        await this.restoreUser(userId);
        this.$appNotifySuccess("Selected User has been restored");
      } catch (error) {
        this.$appNotifyError(error.message);
        this.$bugSnagClient.notify(error);
      } finally {
        this.resetModalData();
      }
    },
    async userDelete(userId: string): Promise<any> {
      try {
        this.loadingText = "Deleting user. Please wait...";
        await this.deleteUser(userId);
        if (this.userRole && this.userRole === "admin") {
          this.$router.push("/admin/users").catch(() => {});
        } else {
          this.$router.push("/agencyAdmin/users").catch(() => {});
        }
        this.$appNotifySuccess("User deletion successfully");
      } catch (e) {
        this.$emit("setError", e.message);
        this.$bugSnagClient.notify(e);
      } finally {
        this.loadingText = "";
        this.resetModalData();
      }
    },
    async banSingleUser(userId: string): Promise<void> {
      try {
        this.loading = true;
        this.loadingText = "Banning user. Please wait...";

        await this.banUser(userId);
        this.$appNotifySuccess("Selected User is Banned");
        this.resetModalData();
      } catch (error) {
        this.$appNotifyError(error.message);
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
      }
    },
    async unbanSingleUser(userId: string): Promise<void> {
      try {
        this.loading = true;
        this.loadingText = "Unbanning user. Please wait...";
        await this.unbanUser(userId);
        this.$appNotifySuccess("Selected User's  Ban is Removed");
        this.resetModalData();
      } catch (error) {
        this.$appNotifyError(error.message);
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
      }
    }
  },
  computed: {
    ...userMapState(["makingApiRequest"]),
    userRole(): null | IUserRoleStrings {
      return this.$getCurrentUser && this.$getCurrentUser.role
        ? this.$getCurrentUser.role
        : null;
    }
  }
});
