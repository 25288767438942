import Vue from "vue";
const { __getText } = Vue.prototype;
import { FormBlock } from "@/components/FormBuilder/types";
import {
  useField,
  useFormSection,
  useFormFieldGroup,
  useTextField
} from "@/components/FormBuilder/Helpers";
import * as selectOptions from "@/helpers/selectOptions";

export const userBase: FormBlock[] = [
  ...useFormSection(
    [
      ...useFormFieldGroup(
        [
          useTextField({
            key: "firstName",
            label: __getText("fields", "firstNameLabel"),
            placeholder: __getText("fields", "firstNameLabel"),
            required: true
          }),
          useTextField({
            key: "lastName",
            label: __getText("fields", "lastNameLabel"),
            placeholder: __getText("fields", "lastNameLabel"),
            required: true
          }),
          useTextField({
            key: "displayName",
            label: __getText("fields", "displayNameLabel"),
            placeholder: __getText("fields", "displayNameLabel"),
            required: true
          })
        ],
        { layout: "3-col" }
      ),
      ...useFormFieldGroup(
        [
          useTextField({
            key: "email",
            label: __getText("fields", "emailFieldLabel"),
            placeholder: __getText("fields", "emailFieldLabel"),
            required: true,
            validations: [
              {
                method: "email",
                error: "invalid email"
              }
            ]
          }),
          useField({
            key: "phone.primary",
            label: __getText("fields", "primaryPhoneNumberLabel"),
            placeholder: __getText("fields", "primaryPhoneNumberLabel"),
            required: true,
            type: "phone"
          }),
          useField({
            key: "phone.secondary",
            label: __getText("fields", "secondaryPhoneNumberLabel"),
            placeholder: __getText("fields", "secondaryPhoneNumberLabel"),
            required: false,
            type: "phone"
          })
        ],
        { layout: "3-col" }
      )
    ],
    {
      title: "Personal Details",
      description: "Basic information about the user"
    }
  ),

  useField({
    key: "physicalAddress",
    label: ["fields", "physicalAddressLabel"],
    required: true,
    type: "address",
    addressType: "physicalAddress"
  }),
  useField({
    key: "mailingAddress",
    label: __getText("fields", "mailingAddressLabel"),
    required: true,
    type: "address",
    addressType: "mailingAddress",
    isPOBOXMailingAddress: "true",
    allowSyncingWithPhysicalAddress: true
  }),
  ...useFormSection(
    [
      ...useFormFieldGroup(
        [
          {
            element: "field",
            classList: ["mb-4 text-lg"],
            properties: {
              key: "companyIds",
              required: true,
              type: "check_box_group",
              options: []
            }
          }
        ],
        { layout: "full" }
      )
    ],
    {
      title: "Companies",
      description: "Select companies user is allowed to quote"
    }
  ),
  ...useFormSection(
    [
      ...useFormFieldGroup(
        [
          {
            element: "field",
            classList: ["text-lg"],
            properties: {
              key: "disabledPermissions",
              required: false,
              type: "check_box_group",
              options: selectOptions.permissionOptions
            }
          }
        ],
        { layout: "full" }
      )
    ],
    {
      title: "Disabled Permissions",
      description: "Select permissions to disable for users"
    }
  )
];
